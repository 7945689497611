import { useContext, useEffect, useState } from "react";
import {
  AttentionBox,
  Dropdown,
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ConnectionContext from "../../context/ConnectionContext";
import banana from "../../images/banana.svg";
import basil from "../../images/basil.svg";
import blueberry from "../../images/blueberry.svg";
import flamingo from "../../images/flamingo.svg";
import grape from "../../images/grape.svg";
import graphite from "../../images/graphite.svg";
import lavender from "../../images/lavender.svg";
import peacock from "../../images/peacock.svg";
import sage from "../../images/sage.svg";
import tangerine from "../../images/tangerine.svg";
import tomato from "../../images/tomato.svg";
import { COLOR_FIELD } from "../../utils/constants";

// Colors retured in Google Calendar API are different from actualy colors they show in the UI.
// Following mapping shows the correct color ID to actual color which is used in the GC UI.
//
// API color ID, API color code, UI color name, UI color code
// 1, #A4BDFC, Lavender, #7986CB
// 2, #7AE7BF, Sage, #33B679
// 3, #DBADFF, Grape, #8E24AA
// 4, #FF887C, Flamingo, #E67C73
// 5, #FBD75B, Banana, #F6BF26
// 6, #FFB878, Tangerine, #F4511E
// 7, #46D6DB, Peacock, #039BE5
// 8, #E1E1E1, Graphite, #616161
// 9, #5484ED, Blueberry, #3F51B5
// 10, #51B749, Basil, #0B8043
// 11, #DC2127, Tomato, #D50000
//
// Ordering as ordered in Google Calendar UI (except Peacock which is placed first).
export const colorOptions = [
  { value: "7", label: "Peacock", leftAvatar: peacock },
  { value: "11", label: "Tomato", leftAvatar: tomato },
  { value: "4", label: "Flamingo", leftAvatar: flamingo },
  { value: "6", label: "Tangerine", leftAvatar: tangerine },
  { value: "5", label: "Banana", leftAvatar: banana },
  { value: "2", label: "Sage", leftAvatar: sage },
  { value: "10", label: "Basil", leftAvatar: basil },
  { value: "9", label: "Blueberry", leftAvatar: blueberry },
  { value: "1", label: "Lavender", leftAvatar: lavender },
  { value: "3", label: "Grape", leftAvatar: grape },
  { value: "8", label: "Graphite", leftAvatar: graphite },
];

const ColorMappingModal = ({ showModal, setShowModal }) => {
  const fieldId = COLOR_FIELD;
  const { columns, mapping, setMapping } = useContext(ConnectionContext);

  const [defaultColorId, setDefaultColorId] = useState(mapping[fieldId]?.defaultColorId || "7");
  const [statusColumnId, setStatusColumnId] = useState(
    mapping[fieldId]?.statusColumnId || undefined
  );
  const [colorValueMapping, setColorValueMapping] = useState(
    mapping[fieldId]?.colorValueMapping || {}
  );
  const [valueOptions, setValueOptions] = useState([]);
  const [duplicateValuesSelected, setDuplicateValuesSelected] = useState(false);

  const columnOptions = columns
    .filter((column) => column.type === "status")
    .map(({ id, title, settings_str }) => ({
      value: id,
      label: title,
      possibleValues: JSON.parse(settings_str).labels,
    }));

  useEffect(() => {
    if (statusColumnId) {
      const { possibleValues } = columnOptions.filter(
        (option) => option.value === statusColumnId
      )[0];

      setValueOptions(
        Object.entries(possibleValues)
          // key equals "5" for the default status label.
          .filter(([key]) => key !== "5")
          .map(([key, value]) => ({ value: key, label: value }))
      );
    }
  }, [columns, defaultColorId, statusColumnId]);

  useEffect(() => {
    const selectedValues = Object.values(colorValueMapping).filter(Boolean);
    const uniqueValues = new Set(selectedValues);
    setDuplicateValuesSelected(selectedValues.length !== uniqueValues.size);
  }, [colorValueMapping]);

  const onCancel = () => {
    setShowModal(false);
  };

  const onConfirm = () => {
    if (duplicateValuesSelected) {
      return;
    }

    const finalValue = { defaultColorId };
    if (statusColumnId && Object.values(colorValueMapping).filter(Boolean).length) {
      finalValue.statusColumnId = statusColumnId;
      finalValue.colorValueMapping = colorValueMapping;
    }
    setMapping({ ...mapping, [fieldId]: finalValue });

    onCancel();
  };

  const getOrderedColorOptions = () => {
    const filledOptions = colorOptions.filter(
      (option) => option.value !== defaultColorId && colorValueMapping[option.value]
    );
    const unfilledOptions = colorOptions.filter(
      (option) => option.value !== defaultColorId && !colorValueMapping[option.value]
    );
    return [...filledOptions, ...unfilledOptions];
  };

  return (
    <Modal contentSpacing onClose={onCancel} show={showModal} width={550}>
      <ModalHeader>
        <Heading type="h2">Setup Color Mapping</Heading>
      </ModalHeader>
      <ModalContent>
        <Flex
          direction="Column"
          gap={20}
          className="modal modal-flex"
          style={{ marginTop: "1rem", minHeight: "50vh" }}
        >
          <Flex justify="SpaceBetween" className="modal-flex">
            <Text type="text1">Default Color</Text>
            <div className="selector">
              <Dropdown
                size="small"
                options={colorOptions}
                value={colorOptions.find((option) => option.value === defaultColorId)}
                onChange={(option) => {
                  setDefaultColorId(option.value);
                  setColorValueMapping({ ...colorValueMapping, [option.value]: undefined });
                }}
                clearable={false}
                className="dropdown-stories-styles_with-chips"
              />
            </div>
          </Flex>

          <Flex justify="SpaceBetween" className="modal-flex">
            <Text type="text1">Status Column (optional)</Text>
            <div className="selector">
              <Dropdown
                size="small"
                options={columnOptions}
                value={columnOptions.find((option) => option.value === statusColumnId)}
                onChange={(option) => setStatusColumnId(option?.value)}
                placeholder="Select status column"
              />
            </div>
          </Flex>

          {statusColumnId && (
            <>
              <Text type="text1" style={{ marginTop: "1rem" }}>
                Map Color With Status Values
              </Text>

              {duplicateValuesSelected && (
                <AttentionBox text="Duplicate status values selected" type="danger" />
              )}

              {getOrderedColorOptions().map(({ value, label, leftAvatar }) => (
                <Flex key={value} justify="SpaceBetween" className="modal-flex">
                  <Flex gap={8} align="Center">
                    <img
                      src={leftAvatar}
                      alt={label}
                      width={16}
                      height={16}
                      style={{ borderRadius: "50%" }}
                    />
                    <Text type="text1">{label}</Text>
                  </Flex>
                  <div className="selector">
                    <Dropdown
                      size="small"
                      options={valueOptions}
                      value={valueOptions.find(
                        (option) => option?.value === colorValueMapping[value]
                      )}
                      onChange={(option) =>
                        setColorValueMapping({ ...colorValueMapping, [value]: option?.value })
                      }
                      placeholder="Select status value"
                    />
                  </div>
                </Flex>
              ))}
            </>
          )}
        </Flex>
      </ModalContent>

      <ModalFooterButtons
        onPrimaryButtonClick={onConfirm}
        onSecondaryButtonClick={onCancel}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
};

export default ColorMappingModal;
