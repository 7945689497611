import { useContext } from "react";
import { Flex } from "monday-ui-react-core";
import "monday-ui-react-core/tokens";
import AppHeading from "../components/common/AppHeading";
import MessageToast from "../components/common/MessageToast";
import SubscribeNudge from "../components/common/SubscribeNudge";
import ButtonGroup from "../components/integration/ButtonGroup";
import Recipe from "../components/integration/Recipe";
import RecurringEventAdvanceDaysRecipe from "../components/integration/RecurringEventAdvanceDaysRecipe";
import StatusRecipe from "../components/integration/StatusRecipe";
import ConnectionContext from "../context/ConnectionContext";

const recipes = [
  { type: "1", text: "When item is created in board, create linked event in calendar" },
  { type: "2", text: "When item column values change, sync changes to linked calendar event" },
  { type: "3", text: "When event is created in calendar, create linked item in board" },
  { type: "4", text: "When event field values change, sync changes to linked board item" },
  {
    type: "5",
    text: "When status column {statusColumnId} changes to value {statusValue} , create linked event in calendar",
  },
  { type: "6", text: "When item is deleted from board, delete linked event from calendar" },
  { type: "7", text: "When event is deleted from calendar, delete linked item from board" },
  {
    type: "8",
    text: "When status column {statusColumnId} changes to value {statusValue} , delete linked event in calendar",
  },
  { type: "9", text: "Create items for recurring calendar events upto {number} days in advance" },
  { type: "10", text: "Allow event invitees/guests to update the event" },
  { type: "11", text: "Add Google Meet link in the event" },
  { type: "12", text: "Mark created event as 'Free'" },
];

const IntegrationSetup = () => {
  const { isSubitemMode } = useContext(ConnectionContext);

  return (
    <>
      <AppHeading />

      <Flex direction="Column" gap={20}>
        {recipes.map(({ type, text }) =>
          type === "5" || type === "8" ? (
            <StatusRecipe
              id={type}
              type={type}
              recipeText={text.replace("item", isSubitemMode ? "subitem" : "item")}
            />
          ) : type === "9" ? (
            !isSubitemMode && (
              <RecurringEventAdvanceDaysRecipe id={type} type={type} recipeText={text} />
            )
          ) : type === "3" ? (
            !isSubitemMode && <Recipe id={type} type={type} recipeText={text} />
          ) : (
            <Recipe
              id={type}
              type={type}
              recipeText={text.replace("item", isSubitemMode ? "subitem" : "item")}
            />
          )
        )}
      </Flex>

      <SubscribeNudge />
      <ButtonGroup />

      <MessageToast />
    </>
  );
};

export default IntegrationSetup;
