const GOOGLE_CALENDAR_SYNC = "Google Calendar Sync";
const OUTLOOK_CALENDAR_SYNC = "Outlook Calendar Sync";

// 2 way synced fields.
const TITLE_FIELD = "title";
const START_TIME_FIELD = "startTime";
const END_TIME_FIELD = "endTime";
const TIMELINE_FIELD = "timeline";
const DURATION_FIELD = "duration";
const DESCRIPTION_FIELD = "description";
const LOCATION_FIELD = "location";
const COLOR_FIELD = "color";

// 1 way synced fields (board -> calendar).
const INVITEES_FIELD = "invitees";
const CUSTOM_DESCRIPTION_FIELD = "customDescription";

// 1 way synced fields (calendar -> board).
const EVENT_LINK_FIELD = "eventLink";
const EVENT_STATUS_FIELD = "eventStatus";

// Special values.
const TIMELINE_COLUMN_SPECIAL_VALUE = "%timeline_column%";
const FIXED_DURATION_SPECIAL_VALUE = "%fixed_duration%";
const START_END_COLUMNS_SPECIAL_VALUE = "%start_end_columns%";
const END_COLUMN_SPECIAL_VALUE = "%end_column%";
const CUSTOM_DESCRIPTION_SPECIAL_VALUE = "%custom_description%";

// Settings.
const SYNC_PERIOD = "syncPeriod";

// API endpoints.
const BASE_API_URL = "/api/";
const CONNECTION_API_URL = BASE_API_URL + "connection/";
const AUTH_API_URL = BASE_API_URL + "auth/";

export {
  GOOGLE_CALENDAR_SYNC,
  OUTLOOK_CALENDAR_SYNC,
  TITLE_FIELD,
  START_TIME_FIELD,
  END_TIME_FIELD,
  TIMELINE_FIELD,
  DURATION_FIELD,
  DESCRIPTION_FIELD,
  LOCATION_FIELD,
  COLOR_FIELD,
  INVITEES_FIELD,
  CUSTOM_DESCRIPTION_FIELD,
  EVENT_LINK_FIELD,
  EVENT_STATUS_FIELD,
  TIMELINE_COLUMN_SPECIAL_VALUE,
  FIXED_DURATION_SPECIAL_VALUE,
  START_END_COLUMNS_SPECIAL_VALUE,
  END_COLUMN_SPECIAL_VALUE,
  CUSTOM_DESCRIPTION_SPECIAL_VALUE,
  SYNC_PERIOD,
  BASE_API_URL,
  CONNECTION_API_URL,
  AUTH_API_URL,
};
