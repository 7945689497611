import { useContext, useState } from "react";
import { Flex, Text, Button, Icon } from "monday-ui-react-core";
import { Delete } from "monday-ui-react-core/icons";
import ConnectionContext, { fieldInfos } from "../../context/ConnectionContext";
import { COLOR_FIELD } from "../../utils/constants";
import ColorMappingModal, { colorOptions } from "./ColorMappingModal";

const ColorSelector = () => {
  const fieldId = COLOR_FIELD;
  const fieldInfo = fieldInfos[fieldId];
  const { mapping, setMapping, areFieldsDisabled } = useContext(ConnectionContext);

  const [showColorMappingModal, setShowColorMappingModal] = useState(false);

  const defaultColor = mapping[fieldId]?.defaultColorId
    ? colorOptions.find((option) => option.value === mapping[fieldId].defaultColorId)
    : null;

  const handleDelete = () => {
    const newMapping = { ...mapping };
    delete newMapping[fieldId];
    setMapping(newMapping);
  };

  return (
    <>
      <Flex gap={10}>
        <Text type="text1" className="label">
          {fieldInfo.name}
        </Text>

        <div className="selector">
          <Flex gap={5}>
            <Button
              kind="secondary"
              onClick={() => setShowColorMappingModal(true)}
              disabled={areFieldsDisabled}
              style={{ width: "100%", height: "2rem" }}
            >
              {defaultColor ? (
                <Flex gap={8} align="Center">
                  <img
                    src={defaultColor.leftAvatar}
                    alt={defaultColor.label}
                    width={16}
                    height={16}
                    style={{ borderRadius: "50%" }}
                  />
                  <Text>{mapping[fieldId].statusColumnId ? "+ Status Based" : "Fixed Color"}</Text>
                </Flex>
              ) : (
                <Text>Set Color Mapping</Text>
              )}
            </Button>
            {defaultColor && (
              <Button
                kind="secondary"
                onClick={handleDelete}
                disabled={areFieldsDisabled}
                size="small"
              >
                <Icon icon={Delete} />
              </Button>
            )}
          </Flex>
        </div>
      </Flex>

      <ColorMappingModal
        showModal={showColorMappingModal}
        setShowModal={setShowColorMappingModal}
      />
    </>
  );
};

export default ColorSelector;
