import { useContext, useState } from "react";
import { Flex, Text, Dropdown, Tooltip } from "monday-ui-react-core";
import InfoIcon from "monday-ui-react-core/dist/icons/Info";
import ConnectionContext, { fieldInfos } from "../../context/ConnectionContext";
import {
  START_TIME_FIELD,
  END_TIME_FIELD,
  TIMELINE_FIELD,
  DESCRIPTION_FIELD,
  TIMELINE_COLUMN_SPECIAL_VALUE,
  FIXED_DURATION_SPECIAL_VALUE,
  START_END_COLUMNS_SPECIAL_VALUE,
  CUSTOM_DESCRIPTION_SPECIAL_VALUE,
  DURATION_FIELD,
  END_COLUMN_SPECIAL_VALUE,
} from "../../utils/constants";
import AddColumnModal from "./AddColumnModal";

const FieldOptionSelector = ({ fieldId }) => {
  const fieldInfo = fieldInfos[fieldId];
  const {
    approvedScopes,
    columns,
    mapping,
    setMapping,
    setTimelineFieldEnabled,
    setDurationFieldEnabled,
    setCustomDescriptionBuilderEnabled,
    areFieldsDisabled,
  } = useContext(ConnectionContext);

  const [showAddColumnModal, setShowAddColumnModal] = useState(false);

  const columnOptions = fieldInfo.simpleField
    ? fieldInfo.values.map((value) => ({ value, label: `${value} ${fieldInfo.suffix}` }))
    : [
        ...columns
          .filter((column) => fieldInfo.columnTypes.includes(column.type))
          .map(({ id, title }) => ({ value: id, label: title })),
        { value: "add_new_column", label: "+ Add new column" },
      ];

  {
    let extraOption;
    switch (fieldId) {
      case START_TIME_FIELD: {
        extraOption = [TIMELINE_COLUMN_SPECIAL_VALUE, "< Use Timeline Column >"];
        break;
      }
      case END_TIME_FIELD: {
        extraOption = [FIXED_DURATION_SPECIAL_VALUE, "< Use Fixed Duration >"];
        break;
      }
      case TIMELINE_FIELD: {
        extraOption = [START_END_COLUMNS_SPECIAL_VALUE, "< Use Start End Columns >"];
        break;
      }
      case DURATION_FIELD: {
        extraOption = [END_COLUMN_SPECIAL_VALUE, "< Use End Time Column >"];
        break;
      }
      case DESCRIPTION_FIELD: {
        if (approvedScopes.includes("users:read") && approvedScopes.includes("teams:read")) {
          extraOption = [CUSTOM_DESCRIPTION_SPECIAL_VALUE, "< 1-Way Custom Description >"];
        }
        break;
      }
    }

    if (extraOption) {
      columnOptions.push({ value: extraOption[0], label: extraOption[1] });
    }
  }

  const selectedOption = mapping[fieldId]
    ? columnOptions.filter((option) => option.value === mapping[fieldId])[0]
    : null;

  const onChange = (option) => {
    const value = option?.value;
    if (value === "add_new_column") {
      setShowAddColumnModal(true);
    } else {
      switch (value) {
        case TIMELINE_COLUMN_SPECIAL_VALUE: {
          setDurationFieldEnabled(false);
          setTimelineFieldEnabled(true);
          setMapping({
            ...mapping,
            [START_TIME_FIELD]: "",
            [END_TIME_FIELD]: "",
            [DURATION_FIELD]: undefined,
          });
          break;
        }
        case FIXED_DURATION_SPECIAL_VALUE: {
          setDurationFieldEnabled(true);
          setMapping({ ...mapping, [END_TIME_FIELD]: "" });
          break;
        }
        case START_END_COLUMNS_SPECIAL_VALUE: {
          setTimelineFieldEnabled(false);
          setMapping({ ...mapping, [TIMELINE_FIELD]: "" });
          break;
        }
        case END_COLUMN_SPECIAL_VALUE: {
          setDurationFieldEnabled(false);
          setMapping({ ...mapping, [DURATION_FIELD]: undefined });
          break;
        }
        case CUSTOM_DESCRIPTION_SPECIAL_VALUE: {
          setCustomDescriptionBuilderEnabled(true);
          setMapping({ ...mapping, [fieldId]: value });
          break;
        }
        default: {
          setMapping({ ...mapping, [fieldId]: value ?? "" });
        }
      }
    }
  };

  return (
    <>
      <Flex gap={10} key={columns}>
        <Text type="text1" className="label">
          {fieldInfo.name}
          {fieldInfo.tooltipText && (
            <Tooltip content={fieldInfo.tooltipText} withMaxWidth position="left">
              <InfoIcon size={15} />
            </Tooltip>
          )}
        </Text>

        <div className="selector">
          <Dropdown
            size="small"
            clearable={fieldInfo.clearable}
            options={columnOptions}
            value={selectedOption}
            disabled={areFieldsDisabled}
            onChange={onChange}
          />
        </div>
      </Flex>

      {fieldInfo.columnTypes && (
        <AddColumnModal
          showAddColumnModal={showAddColumnModal}
          setShowAddColumnModal={setShowAddColumnModal}
          columnTypes={fieldInfo.columnTypes}
          setNewColumnOption={onChange}
        />
      )}
    </>
  );
};

export default FieldOptionSelector;
